@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

$primary: #E75074;
$primary-opacity: #E75073BA;
$secondary: #EAEAEA;
$success: #338f4d;
$success-opacity: #338f4dBA;
$volunteering: #6130a1;
$volunteering-opacity: #6130a1BA;
$warning: #E89535;
$info: #3C8EB0;
$light: #FFF;
$font-family-base: 'Red Hat Display', sans-serif;
$font-color: #313131;
$accordion-active-color: #F8D7E0;
$black: #000;
$blue: #3C8EB0;
$green: #338f4d;
$spacer: 1rem !default;
$font-size-base: 1rem !default;

h1 {
  overflow-wrap: break-word;
}
h2 {
  font-weight: 700;
  overflow-wrap: break-word;
}
h3 {
  overflow-wrap: break-word;
}
h4 {
  overflow-wrap: break-word;
}
h5 {
  overflow-wrap: break-word;
}
h6 {
  overflow-wrap: break-word;
}
a {
  cursor: pointer !important;
  text-decoration: none !important;
  margin: 0px 4px;
  font-size: $font-size-base !important;
  overflow-wrap: break-word;
  & > span {
    cursor: pointer !important;
    text-decoration: none !important;
  }
}
b {
  font-style: normal;
  font-weight: 700;
}
strong {
  font-style: normal;
  font-weight: 700;
}
p {
  overflow-wrap: break-word;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
span {
  overflow-wrap: break-word;
}
a.nav-link:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}
button {
  font-weight: 700;
  padding-left: 24px !important;
  padding-right: 24px !important;
  border-radius: 12px !important;
}
table {
  border-collapse: collapse  !important;
}
th {
  font-weight: 400;
  border-bottom: 1px solid black !important;
}
.table-bordered th:not(:first-child) {
  border-left: 0 none !important;
}
.table-bordered th:not(:last-child) {
  border-right: 0 none !important;
}
.table-bordered td:not(:first-child) {
  border-left: 0 none !important;
}
.table-bordered td:not(:last-child) {
  border-right: 0 none !important;
}
.accordion-item {
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(79, 61, 67, 0.25);
  margin-bottom: 16px;
  border: none !important;
}
.accordion-button {
  font-size: 20px;
  font-weight: 500;
  border-radius: 0 !important;
  &:not(.collapsed) {
    font-weight: '700';
    box-shadow: none !important;
    background-color: $accordion-active-color !important;
  }
}
.accordion-collapse {
  background-color: $accordion-active-color;
}
.dropdown-menu {
  border-radius: 0px !important;
}
.modal {
  z-index: 10000 !important;
}
.flex-direction-column {
  flex-direction: column;
}
.w-10 { width: 10% }
.w-15 { width: 15% }
.w-20 { width: 20% }
.w-25 { width: 25% }
.w-30 { width: 30% }
.w-40 { width: 40% }
.w-50 { width: 50% }
.w-60 { width: 60% }
.w-70 { width: 70% }
.w-80 { width: 80% }
.w-90 { width: 90% }
.w-100 { width: 100% }

.pl-1 { padding-left: ($spacer * .25) !important; }
.pl-2 { padding-left: ($spacer * .5) !important; }
.pl-3 { padding-left: ($spacer * .75) !important; }
.pl-4 { padding-left: ($spacer * 1) !important; }
.pl-5 { padding-left: ($spacer * 1.25) !important; }

.pr-1 { padding-right: ($spacer * .25) !important; }
.pr-2 { padding-right: ($spacer * .5) !important; }
.pr-3 { padding-right: ($spacer * .75) !important; }
.pr-4 { padding-right: ($spacer * 1) !important; }
.pr-5 { padding-right: ($spacer * 1.25) !important; }

.pt-1 { padding-top: ($spacer * .25) !important; }
.pt-2 { padding-top: ($spacer * .5) !important; }
.pt-3 { padding-top: ($spacer * .75) !important; }
.pt-4 { padding-top: ($spacer * 1) !important; }
.pt-5 { padding-top: ($spacer * 1.25) !important; }

.pb-1 { padding-bottom: ($spacer * .25) !important; }
.pb-2 { padding-bottom: ($spacer * .5) !important; }
.pb-3 { padding-bottom: ($spacer * .75) !important; }
.pb-4 { padding-bottom: ($spacer * 1) !important; }
.pb-5 { padding-bottom: ($spacer * 1.25) !important; }

.ml-1 { margin-left: ($spacer * .25) !important; }
.ml-2 { margin-left: ($spacer * .5) !important; }
.ml-3 { margin-left: ($spacer * .75) !important; }
.ml-4 { margin-left: ($spacer * 1) !important; }
.ml-5 { margin-left: ($spacer * 1.25) !important; }

.mr-1 { margin-right: ($spacer * .25) !important; }
.mr-2 { margin-right: ($spacer * .5) !important; }
.mr-3 { margin-right: ($spacer * .75) !important; }
.mr-4 { margin-right: ($spacer * 1) !important; }
.mr-5 { margin-right: ($spacer * 1.25) !important; }

.mt-1 { margin-top: ($spacer * .25) !important; }
.mt-2 { margin-top: ($spacer * .5) !important; }
.mt-3 { margin-top: ($spacer * .75) !important; }
.mt-4 { margin-top: ($spacer * 1) !important; }
.mt-5 { margin-top: ($spacer * 1.25) !important; }

.mb-1 { margin-bottom: ($spacer * .25) !important; }
.mb-2 { margin-bottom: ($spacer * .5) !important; }
.mb-3 { margin-bottom: ($spacer * .75) !important; }
.mb-4 { margin-bottom: ($spacer * 1) !important; }
.mb-5 { margin-bottom: ($spacer * 1.25) !important; }

.form-switch .form-check-input {
  height: 1.5rem;
  width: 3rem !important;
  border-radius: 3rem;
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
  &:focus {
    background-color: $primary;
    border-color: $primary;
  }
}

.form-switch-blue .form-check-input {
  height: 1.5rem;
  width: 3rem !important;
  border-radius: 3rem;
  &:checked {
    background-color: $blue;
    border-color: $blue;
  }
  &:focus {
    background-color: $blue;
    border-color: $blue;
  }
}

.form-switch-purple .form-check-input {
  height: 1.5rem;
  width: 3rem !important;
  border-radius: 3rem;
  &:checked {
    background-color: $volunteering;
    border-color: $volunteering;
  }
  &:focus {
    background-color: $volunteering;
    border-color: $volunteering;
  }
}

@import '~bootstrap/scss/bootstrap';