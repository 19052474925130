:root {
  --primary: #E75074;
  --primary-opacity: #e75073BA;
  --blue: #3C8EB0;
  --connect-color: #E89535;
  --empower-color: #D74952;
  --engage-color: #4780D6;
  --volunteering-color: #6130a1;
  --connect-color-opacity: #FFF2DA;
  --empower-color-opacity: #FEE9E9;
  --engage-color-opacity: #E5F0FF;
  --volunteering-color-opacity: #c3a3ec;
  --dark-gray-color: #D9D9D9;
  --light-gray-color: #F1F1F1;
  --general-color: #656565;
  --general-color-opacity: #ededed;
  --white: #FFF;
  --black: #000;
  --muted: #AAA;
  --print-blue-color: rgb(38, 46, 74);
  --print-red-color: rgb(234, 83, 77);
  --print-gray-color: rgb(249, 247, 244);
  --box-shadow: 0px 4px 12px 0px rgba(79, 61, 67, 0.25);
  --input-box-shadow: 0 0 0 0.2rem rgba(215, 73, 82, 0.25);
  --font-family-base: 'Red Hat Display', sans-serif;
  --font-color: #313131;
}

.table {
  border-color: var(--dark-gray-color);
  border-radius: 8px !important;
  & tr:last-child {
    border-width: 0px;
    & td {
      border-width: 0px;
    }
  }
}
.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.content {
  flex: 1 0 auto;
}
.content.vista {
  background-color: #404040;
  color: var(--white);
}
.box-shadow {
  box-shadow: var(--box-shadow);
}

.muted {
  color: var(--muted);
}

.col {
  margin-bottom: 24px;
}

.social-icon {
  background-color: var(--dark-gray-color);
  border: 1px solid var(--dark-gray-color);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  text-align: center;
  & svg {
    position: relative;
    top: 8px;
    left: 0px;
  }
}

a.nav-link {
  color: var(--black);
  margin-left: 16px;
  margin-right: 16px;
}
.btn {
  margin: 4px 0px;
}
.btn-primary {
  color: var(--white) !important;
}

.btn-secondary {
  color: var(--empower-color) !important;
}

.btn-warning {
  color: var(--white) !important;
}

.btn-info {
  color: var(--white) !important;
}
.btn-info {
  color: var(--white) !important;
}

.btn-volunteering {
  --bs-btn-color: #FFF !important;
  --bs-btn-bg: var(--volunteering-color) !important;
  --bs-btn-hover-color: #FFF !important;
  --bs-btn-hover-bg: var(--volunteering-color-opacity) !important;
  --bs-btn-hover-border-color: var(--volunteering-color-opacity) !important;
  --bs-btn-active-color: #FFF !important;
  --bs-btn-active-bg: var(--volunteering-color-opacity) !important;
  --bs-btn-active-border-color: var(--volunteering-color-opacity) !important;
}

.dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
}

.primary-background {
  background-color: var(--primary-color) !important;
}

.general-background {
  background-color: var(--general-color) !important;
}

.general-color {
  color: var(--general-color) !important;
}

.engage-background {
  background-color: var(--engage-color) !important;
}

.text-engage {
  color: var(--engage-color) !important;
}

.empower-background {
  background-color: var(--empower-color) !important;
}

.text-empower {
  color: var(--empower-color) !important;
}

.connect-background {
  background-color: var(--connect-color) !important;
}

.text-connect {
  color: var(--connect-color) !important;
}

.volunteering-background {
  background-color: var(--volunteering-color) !important;
}

.text-volunteering {
  color: var(--volunteering-color) !important;
}

.light-gray-background {
  background-color: var(--light-gray-color) !important;
}

.dark-gray-background {
  background-color: var(--dark-gray-color) !important;
}

.white-background {
  background-color: var(--white) !important;
}

.text-blue {
  color: var(--blue) !important;
}

.text {
  color: var(--black) !important;
}

button.scroll-to-top {
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 48px !important;
  color: var(--empower-color);
  padding: 8px !important;
  & svg {
    font-size: 24px;
    color: var(--white);
  }
}

/* HEADER */
.header {
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
}

.header-vista {
  padding: 16px;
  background-color: #404040;
  cursor: pointer;
  & a {
    color: var(--white);
    cursor: pointer;
    &:hover {
      color: var(--white);;
    }
    &:active {
      color:  var(--white);;
    }
  }
  & h1, h2, h3, h4, h5, h6 {
    color: var(--white)
  }
  & .nav-link {
    color: var(--white)
  }
  & .dropdown-menu {
    max-height: 400px;
    width: 200px;
    overflow: scroll;
  }
  & .nav-item {
    color: var(--white);
  }
}

.header-function {
  margin-left: 0px !important;
  margin-right: 0px !important;

  &:hover {
    color: var(--white);
    text-decoration: none !important;
  }

  .header-function-image {
    height: 30px;
    width: 30px;
  }

  .header-function-text {
    margin-left: 20px;
  }
}

.header-function-engage:hover {
  background-color: var(--engage-color);
  & .header-function-image {
    content: url("../assets/icons/white/engage.svg");
  }
}

.header-function-empower:hover {
  background-color: var(--empower-color);
  & .header-function-image {
    content: url("../assets/icons/white/empower.svg");
  }
}

.header-function-connect:hover {
  background-color: var(--connect-color);
  & .header-function-image {
    content: url("../assets/icons/white/connect.svg");
  }
}
.header-function-volunteering:hover {
  background-color: var(--volunteering-color);
  & .header-function-image {
    content: url("../assets/icons/white/volunteer.svg");
  }
}

.header-function.profile:hover {
  background-color: var(--general-color);
  border-radius: 4px;
}

.header-function:hover .header-function-image {
  color: var(--white);
}

.header-language {
  & .header-function:hover {
    border-radius: 0px;
    background-color: var(--primary);
    color: var(--white)
  }
}

.header-user-profile {
  & .dropdown-toggle::after {
    display: none;
  }
  & .header-function:hover {
    border-radius: 0px;
    background-color: var(--primary);
    color: var(--white)
  }
  & img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    object-fit: cover;
    background-repeat: no-repeat;
  }
}

/* FOOTER */
.footer {
  & a {
    text-decoration: none;
  }

  & p {
    color: var(--muted)
  }
}

.logo-name {
  padding-left: 8px;
  color: var(--blue);

  :first-child {
    font-size: 13px;
    text-transform: uppercase;
    line-height: 13px !important;
  }

  :last-child {
    line-height: 24px !important;
    font-size: 24px;
  }
}

.title {
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.8px;
  text-align: center;
}

.footnote {
  text-align: center;
  margin: 40px 0px;
  & a {
    font-size: inherit !important;
  }
}

.line-top {
  border-bottom: 1px solid var(--black);
  margin: 24px 0px 32px 0;
  position: relative;
  left: 45%;
  right: 45%;
  width: 10%;
}

.line-bottom {
  border-bottom: 1px solid var(--black);
  margin: 72px 0px 24px 0;
  position: relative;
  left: 45%;
  right: 45%;
  width: 10%;
}

.line-left {
  border-left: 2px solid var(--muted);
  margin: 24px 48px 0px 48px;
}
.line-right {
  border-left: 2px solid var(--muted);
  margin: 24px 48px 0px 48px;
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--light-gray-color);
  padding: 8px 24px;
  border-radius: 16px;
  flex-wrap: wrap;
  & img {
    padding: 4px;
    height: 28px;
    background-color: var(--dark-gray-color);
    margin-right: 8px;
  }
  & button {
    transition: none !important;
    border: none !important;
    background-color: transparent !important;
    padding-left: 12px !important;
    padding-right: 12px  !important;
  }
  & button.inactive {
    & img {
      background-color: var(--dark-gray-color) !important;
    }
    & span {
      color: var(--dark-gray-color) !important;
    }
  }
}

.clickable {
  cursor: pointer;
  text-decoration: none;
}

/* Home */
.welcome-container {
  background-image: url('/src/assets/icons/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 64px;
  & h2 {
    color: var(--white);
    font-weight: 900;
    margin-top: 8px;
  }
  & p {
    color: var(--white);
  }
  & button {
    margin-bottom: 16px;
  }
}
.welcome-content {
  display: flex;
  flex-direction: row;
}
.horizontal-line {
  margin: 0px 16px;
  border: 2px solid var(--white);
  border-radius: 1px;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  box-shadow: var(--box-shadow);
  padding: 48px 24px;
}

.card-fair {
  padding: 0px;
  &.small {
    align-items: flex-start;
  }

}
.card-cover-image {
  height: 300px;
  width: 100%;
  object-fit: cover;
  &.small {
    height: 180px;
    width: 100%;
  }
}

.card-type-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

/* Home - function */
.card-function {
  color: var(--white);
}

.card-function h4 {
  text-transform: uppercase !important;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.35px;
  padding: 24px 0;
}

.card-function img {
  color: var(--white);
  height: 120px;
  width: 120px;
}

.card-function button {
  margin-top: 40px;
  font-weight: 500;
}

/* Home- Benefits */
.card-benefit {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.card-benefit-circle {
  width: 220px;
  height: 220px;
  border-radius: 220px;
  background-color: var(--dark-gray-color);
}

.card-benefit-circle img {
  width: 96px;
  height: 96px;
  position: relative;
  top: calc((220px - 96px) / 2);
  left: calc((220px - 96px) / 2);
}

.card-benefit h4 {
  text-align: center;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.35px;
  padding: 24px 0;
}

/* Home - Opportunity */
.card-opportunity {
  width: 100%;
  border: none;
  text-align: left;
  align-items: flex-start;
  padding: 0px;
  & p {
    white-space: normal;
  }
  & div {
    padding: 8px 16px 0px 16px;
  }
}

/* Home - News */
.card-news {
  width: 100%;
  border: none;
  text-align: left;
  align-items: flex-start;
  padding: 0px;
  & p {
    white-space: normal;
  }
  & div {
    padding: 8px 16px 0px 16px;
  }
}

.card-news.card-general {
  background-color: var(--general-color-opacity);
  & h4,
  & span,
  & small {
    color: var(--general-color);
    font-weight: 500;
  }
}

.card-news.card-connect {
  background-color: var(--connect-color-opacity);
  & h4,
  & span,
  & small {
    color: var(--connect-color);
    font-weight: 500;
  }
}

.card-news.card-volunteering {
  background-color: var(--volunteering-color-opacity);
  & h4,
  & span,
  & small {
    color: var(--volunteering-color);
    font-weight: 500;
  }
}

.card-news.card-engage {
  background-color: var(--engage-color-opacity);
  & h4,
  & span,
  & small {
    color: var(--engage-color);
    font-weight: 500;

  }
}

.card-news.card-empower {
  background-color: var(--empower-color-opacity);
  & h4,
  & span,
  & small {
    color: var(--empower-color);
    font-weight: 500;
  }
}

/* Home - Organizations */
.card-organization {
  >img {
    width: 110px;
    height: 110px;
    border-radius: 110px;
    background-repeat: no-repeat;
    object-fit: cover;
  }

  >h4 {
    font-weight: 700;
    padding: 8px 0px;
  }

  .country-data {
    padding-bottom: 8px;

    >img {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-repeat: no-repeat;
      object-fit: cover;
    }

    >span {
      padding-left: 8px;
    }
  }
}

.pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 113px;
  background: var(--light-gray-color);
  margin: 16px 0px;
  padding: 12px 24px;
  & img {
    padding-right: 12px;
  }
  & span {
    flex-shrink: 1;
  }
}
.pill-sm {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 113px;
  background: var(--light-gray-color);
  margin: 16px 0px;
  padding: 8px 20px;
  & img {
    padding-right: 8px;
  }
  & span {
    flex-shrink: 1;
  }
}


/* Contact */
.pot {
  .contact-card-header {
    background-color: var(--primary) !important;
  }
}
.contact-card {
  box-shadow: var(--box-shadow);
  .contact-card-header {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 16px 32px;
    color: var(--white);
    background-color: var(--blue);
    font-style: italic;
    & img {
      padding-left: 16px;
      width: 120px;
      height: 60px;
    }
  }

  .contact-card-body {
    margin-top: 24px;
    padding: 16px;
  }

  button {
    text-align: center;
  }
}

/* TypeAhead */
.rbt-input-multi.focus {
    border-color: var(--empower-color);
    box-shadow: var(--input-box-shadow);
}
.rbt-token {
  background-color: var(--empower-color-opacity);
  color: var(--empower-color);

}
.rbt-token-active.rbt-close:hover {
  color: var(--empower-color) !important;
}
.rbt-close {
  padding: 2px 4px 0px 4px !important;
  align-self: center !important;
  &:focus {
    border: none !important;
  }
}
.rbt-menu {
  margin-top: 6px;
  > .dropdown-item {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

/* Info */
.info {
  background-color: var(--light-gray-color);
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 8px;
  & label {
    font-size: smaller;
    font-weight: 600;
  }
  & span {
    font-weight: 300;
    display: block;
  }
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.admin-community-filter {
  & button {
    font-size: 18px;
    text-decoration: none !important;
    padding-top: 2px;
    padding-bottom: 2px;
    &:not(:first) {
      padding-left: 0px !important;
    }
    &:not(.active) {
      color: var(--black)
    }
  }
  &:not(:last-child) {
    border-right: 2px solid var(--dark-gray-color);
  }
}

.wizard-steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.wizard-progress {
  position: relative;
  top: 13px;
  left: calc(25% + 12px);
  right: calc(25% + 12px);
  width: calc(50% - 24px);
  height: 2px;
  background-color: var(--light-gray-color);
}
.wizard-step {
  text-align: center;
  overflow: hidden;
}
.wizard-circle {
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: 2px solid var(--light-gray-color);
  background-color: var(--white);
  margin-left: auto;
  margin-right: auto;
  &.active {
    background-color: var(--light-gray-color);
  }
}

.tag {
  background-color: var(--primary);
  border-radius: 4px;
  border: 1px solid var(--primary);
  color: var(--white);
  font-family: sans-serif;
  margin: 3px;
  font-size: 15px;
  padding: 4px 8px;
}
.rdp  {
  margin-left: 0;
  margin-right: 0;
  padding: 8px;
  box-shadow: var(--box-shadow);
  width: 100%;
  --rdp-cell-size: none;
}
.rdp-month {
  width: 100%;
}
.rdp-table {
  width: 100%;
  max-width: 100%;
  --rdp-cell-size: 40px;
}
.rdp-caption_label {
  font-weight: normal;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  color: var(--white);
  background-color: var(--primary);
}
.rdp-button:focus-visible:not([disabled]) {
  color: var(--white);
  background-color: var(--primary-opacity);
}
.rdp-day_today:not(.rdp-day_outside) {
  color: var(--primary);
  font-weight: normal;
}
.rdp-day_selected.rdp-day_today{
  color: var(--white) !important;
}
.rdp-head_cell {
  font-weight: 500;
  text-align: center;
  height: 40px;
  padding: 0;
  text-transform: uppercase;
  border-bottom: 1px solid var(--light-gray-color) !important;
}
.rdp-day_selected {
  color: var(--white);
  background-color: var(--primary);
  &:active {
    color: var(--white);
    background-color: var(--primary);
  }
  &:focus {
    color: var(--white);
    background-color: var(--primary);
  }
  &:hover {
    color: var(--white);
    background-color: var(--primary);
  }
}
.rdp-day_today:not(.rdp-day_outside) {
  color: var(--primary);

}

.function-wallpaper {
  width: 100%;
  height: 600px;
  color: var(--white);
  &.engage {
    background-color: var(--blue);
    & button {
      background-color: var(--white);
      color: var(--blue) !important;
    }
  }
  &.empower {
    background-color: var(--empower-color);
  }
  &.connect {
    background-color: var(--connect-color);
    & button {
      background-color: var(--white);
      color: var(--connect-color) !important;
    }
  }
  &.volunteering {
    background-color: var(--volunteering-color);
    & button {
      background-color: var(--white);
      color: var(--volunteering-color) !important;
    }
  }
}

.badge {
  & img {
    width: 160px;
    height: 80px;
    object-fit: cover;
    background-repeat: no-repeat;
  }
  & a {
    position: relative;
    background-color: var(--white);
    border-radius: 40px;
    border: 1px solid var(--dark-gray-color);
    top: -32px;
    right: 22px;
    padding: 4px 8px;
    z-index: 10;
  }
}
.hotspot-items {
  background-color: var(--light-gray-color);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  & img {
    margin-right: 12px;
  }
  & a {
    vertical-align: middle;
    font-size: 20px;
  }
}

.invitation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--box-shadow);
  padding: 16px;
  margin-bottom: 18px;
}
.invitation-item {
  & span {
    font-weight: 500;
  }
}

.fake-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--dark-gray-color);
  border-radius: 6px;
  padding: 0 0 0 8px;
}
.fake-input.x2 {
  padding-bottom: 16px;
}
.fake-input.x3 {
  padding-bottom: 24px;
}
.fake-input.x4 {
  padding-bottom: 32px;
}
.fake-input.x5 {
  padding-bottom: 40px;
}
.fake-input.x6 {
  padding-bottom: 48px;
}

.event {
  padding: 16px;
  box-shadow: var(--box-shadow);
}

.timer {
  z-index: 9999999999;
  position: relative;
  top: 24px;
  text-align: center;
  margin: 8px;
  margin-bottom: 24px;
}
.timer > span:first-of-type {
  padding: 16px 25px;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--box-shadow);
  color: var(--black);
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__input-container > input {
  width: 100% !important;
  border: 1px solid var(--dark-gray-color);
  border-radius: 6px;
  padding: 6px;
}
.react-datepicker {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
}
.react-datepicker__day--today {
  color: var(--primary);
  background-color: #FFF;
}
.react-datepicker__day--selected {
  color: #FFF !important;
  background-color: var(--primary) !important;
  border-radius: 20px;
}
.react-datepicker__day--keyboard-selected {
  color: #FFF !important;
  background-color: var(--primary) !important;
  border-radius: 20px;
}
.react-datepicker__day:hover {
  color: #FFF !important;
  background-color: var(--primary-opacity) !important;
  border-radius: 20px;
}
.react-datepicker__navigation {
  height: 40px;
  width: 40px;
}
.react-datepicker__navigation-icon:before {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.react-datepicker__current-month {
  font-weight: normal;
}
.react-datepicker-time__header {
  font-weight: normal;
}
.react-datepicker__header {
  background-color: var(--light-gray-color);
}
.react-datepicker__header--time {
  padding-top: 20px;
  padding-bottom: 20px;
}
.react-datepicker__time-list-item:hover {
  background-color: var(--primary-opacity) !important;
  color: #FFF !important;
}
.react-datepicker__time-list-item--selected {
  background-color: var(--primary) !important;
  font-weight: normal !important;

}
/* .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover { */

.session {
  width: 25%;
  padding: 8px 2px 24px 2px;
}
.session-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & small {
    color: var(--muted);
    margin-left: -24px;
    margin-right: -24px;
  }

}
.session-card {
  background-color: var(--general-color);
  padding: 8px 8px;
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  &:not(.open) {
    background-color: var(--dark-gray-color);
  }
}

.language-card {
  border: 1px solid var(--dark-gray-color);
  padding: 4px 15px;
  border-radius: 16px;
  margin: 8px;
  cursor: pointer;
  &.active {
    border-width: 2px;
    border-color: var(--primary);
  }
}

.center {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

.box {
  border: 1px solid var(--dark-gray-color);
  padding: 8px;
  margin-bottom: 16px;
}

.table-bordered {
  border: 1px solid var(--dark-gray-color) !important;
  thead {
    border: 1px solid var(--dark-gray-color) !important;
  }
  tbody {
    border: 1px solid var(--dark-gray-color) !important;
  }
  tr {
    border: 1px solid var(--dark-gray-color) !important;
  }
  th {
    border: 1px solid var(--dark-gray-color) !important;
  }
  td {
    border-width: 1px;
    border: 1px solid var(--dark-gray-color) !important;
  }
  td:not(:last-child) {
    border-right: 1px solid var(--dark-gray-color) !important;
  }
}

.page {
  background-color: var(--white);
  padding: 32px;
  font-size: 1.1rem !important;
  b {
    color: var(--print-blue-color);
  }
  & h1 {
    color: var(--print-red-color);
    font-weight: bold;
    padding-bottom: 1rem;
  }
  & h2 {
    color: var(--print-blue-color);
    font-weight: bold;
    padding-bottom: 1rem;
  }
  & h4 {
    color: var(--print-red-color);
    font-weight: bold;
    padding-bottom: 1rem;
  }
  & h5 {
    color: var(--print-red-color);
    font-weight: bold;
  }
  & h6 {
    font-size: 1.2rem;
    text-decoration: underline;
  }
  & img {
    object-fit: 'contain';
  }
  & a {
    padding: 0px;
    font-size: 1.1rem !important;
    color: var(--print-red-color) !important;
    margin: 0px;
    &.text-volunteering {
      color: var(--volunteering-color) !important;
    }
    &.text-connect {
      color: var(--connect-color) !important;
    }
    &.text-engage {
      color: var(--engage-color) !important;
    }
  }
  & svg > path {
    fill: var(--print-red-color) !important;
  }
  & svg.text-connect > path {
    fill: var(--connect-color) !important;
  }
  & svg.text-volunteering > path {
    fill: var(--volunteering-color) !important;
  }
  & svg.text-engage > path {
    fill: var(--engage-color) !important;
  }
  & ol > li {
    padding-bottom: 16px;
  }
  & table {
    & tbody {
      border-bottom: 1px solid var(--white);
    }
  }
  & table.two-columns {
    ul {
      margin-bottom: 0px;
    }
    &.text-volunteering {
      border: 2px solid black;
      thead {
        tr {
          & th:first-child {
            background-color: var(--volunteering-color-opacity) !important;
            color: var(--white);
            font-weight: 700;
            font-size: larger;
            padding: 16px;
            text-transform: uppercase;
          }
          & th:last-child {
            background-color: var(--volunteering-color);
            color: var(--white);
            font-weight: 700;
            font-size: larger;
            padding: 16px;
            text-transform: uppercase;
          }
        }
      }
      tbody {
        tr {
          border: 1px solid black;
          & td:first-child {
            background-color: var(--volunteering-color-opacity) !important;
            color: var(--white);
            padding: 16px;
            border: 1px solid black;
            font-weight: 700;
            text-transform: uppercase;
          }
          & td:last-child {
            background-color: var(--white);
            color: var(--black);
            padding: 12px;
            border: 1px solid black;
          }
        }
      }
    }
    tr {
      & td:first-child {
        background-color: var(--print-red-color) !important;
        color: var(--white) !important;
      }
      & td:last-child {
        background-color: var(--print-blue-color);
        color: var(--white);
        border: none;
      }
    }
  }
  & table.three-columns {
    tr {
      & td {
        background-color: var(--print-red-color) !important;
        color: var(--white) !important;
      }
      & td:first-child {
        background-color: var(--print-gray-color) !important;
        color: var(--font-color) !important;
      }
      & td:last-child {
        background-color: var(--print-blue-color) !important;
        color: var(--white);
        border: none;
      }
    }
    &.text-volunteering {
      tr {
        & th {
          background-color: var(--volunteering-color);
          color: var(--white) !important;
          border-color: var(--volunteering-color) !important;
        }
        & td {
          background-color: var(--white) !important;
          color: var(--white) !important;
        }
        & td:first-child {
          background-color: var(--volunteering-color-opacity) !important;
          color: var(--white) !important;
          border-top-color: var(--volunteering-color-opacity) !important;
          border-bottom-color: var(--volunteering-color-opacity) !important;
        }
      }
    }
  }
  & button {
    &.btn-primary {
      background-color: var(--print-red-color) !important;
    }
    &.btn-outline-primary {
      border-color: var(--print-red-color) !important;
      color: var(--print-red-color) !important;
      &:hover {
        background-color: var(--print-red-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
      &:active {
        background-color: var(--print-red-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
    }
    &.btn-connect {
      background-color: var(--connect-color) !important;
      border-color: var(--connect-color-opacity) !important;
    }
    &.btn-outline-connect {
      border-color: var(--connect-color) !important;
      color: var(--connect-color) !important;
      &:hover {
        background-color: var(--connect-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
      &:active {
        background-color: var(--connect-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
    }
    &.btn-engage {
      background-color: var(--engage-color) !important;
      border-color: var(--engage-color-opacity) !important;
    }
    &.btn-outline-engage {
      border-color: var(--engage-color) !important;
      color: var(--engage-color) !important;
      &:hover {
        background-color: var(--engage-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
      &:active {
        background-color: var(--engage-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
    }
    &.btn-volunteering {
      background-color: var(--volunteering-color) !important;
    }
    &.btn-outline-volunteering {
      border-color: var(--volunteering-color) !important;
      color: var(--volunteering-color) !important;
      &:hover {
        background-color: var(--volunteering-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
      &:active {
        background-color: var(--volunteering-color) !important;
        color: var(--white) !important;
        & path {
          fill: var(--white) !important;
        }
      }
    }
  }
  .accordion-header {
    padding-bottom: 0px;
  }
  .accordion-item {
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(79, 61, 67, 0.25);
    margin-bottom: 16px;
    border: none !important;
  }
  .accordion-button {
    font-size: 18px;
    font-weight: 500;
    border-radius: 0 !important;
    &:not(.collapsed) {
      font-weight: '700';
      box-shadow: none !important;
      background-color: white !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .accordion-collapse {
    background-color: white;
  }
}
.page-break {
  page-break-before: always;
  margin-top: 40px;
}
@media print {
  .header {
    display: none;
  }
}